import { createStore } from 'vuex'
import { AnyObject } from "./../ts/type";
// 页面卡
const system = {
  state:{
    isPC:false,
    isHorizontal:true,
    androidUrl:"",
    iosUrl:"",
    PCUrl:"",
    macUrl:""
  },
  mutations:{
    isPCSet(state:AnyObject,evt:boolean){state.isPC = evt;},
    isHorizontalSet(state:AnyObject,evt:boolean){state.isHorizontal = evt;},
    androidUrlSet(state:AnyObject,evt:string){state.androidUrl = evt;},
    iosUrlSet(state:AnyObject,evt:string){state.iosUrl = evt;},
    PCUrlSet(state:AnyObject,evt:string){state.PCUrl = evt;},
    macUrlSet(state:AnyObject,evt:string){state.macUrl = evt;}
  }
}

export default createStore({
  modules: {
    system:system
  }
})
