import { useStore } from "vuex";
import { AnyObject } from "./type";
import { RouteToName } from "./chart";
import { RouteRecordName } from 'vue-router';

const G_Undefined = undefined;

export function GetSetting(fn:any) {　　
    let xmlHttp:any = {};
    const url = "./setting.json?" + Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100);
    if (window.XMLHttpRequest) {　　　　 // code for IE7+, Firefox, Chrome, Opera, Safari          　　　　
        xmlHttp = new XMLHttpRequest();　　
    } else { // code for IE6, IE5       　　　　
        xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");　　
    }　
    if (xmlHttp === null)　 return;　　
    xmlHttp.open("GET", url, true);　　
    xmlHttp.onreadystatechange = function() { //发送事件后，收到信息了调用函数
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) fn(xmlHttp.responseText);　
    }　
    xmlHttp.send();
}

export function SetupMapState(...arg:string[]) :AnyObject {
    const store = useStore(),
        storeFn:AnyObject = store.state,
        res:AnyObject = {};
    for (const key of arg) {
        res[key] = storeFn[key];
    }
    return res;
}

export function Comparison(val:any,arr:any) :boolean{
    return arr.includes(val);
}

export function CopyJson(obj:any) : AnyObject{
    const type = Object.prototype.toString.call(obj);
    if (obj === null || typeof(obj) !== 'object') return obj;
    const res = (type === '[object Object]') ? {} : [];
    for (const key in obj) {
        if (obj[key] !== null && typeof(obj[key]) === 'object') {
            (res as any)[key] = CopyJson(obj[key]);
        } else {
            (res as any)[key] = obj[key];
        }
    }
    return res;
}

export function NowDateTime(str:string, formatDate:any) :string {
    let date = new Date();
    let res = "";
    if (formatDate !== "") date = new Date(formatDate);
    const month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    switch (str) {
        case "month":
            res = year + "-" + month;
            break;
        case "datetime":
            res = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            break;
        case "date":
            res = year + "-" + month + "-" + day;
            break;
        case "time":
            res = hour + ":" + minute + ":" + second;
            break;
        case "timedate":
            res = day + " " + hour + ":" + minute + ":" + second;
            break;
        default:
            return "";
    }
    return res;
}

export function RightClick(event:MouseEvent,routeName:RouteRecordName| null | undefined):void { //右键事件demo
    const obj = document.getElementById("menuJump"),
        arr = RouteToName(routeName),
        num = arr.length,
        spacingH = 36 * num,
        heiW = document.body.clientHeight,
        widW = document.body.clientWidth,
        heiY = event.clientY,
        widX = event.clientX,
        spacingW = 160,
        diffH = heiW - heiY,
        diffW = widW - widX;
    let heiT = heiY,
        widL = widX;
    if (diffH <= spacingH) heiT = heiY - spacingH;
    if (diffW <= spacingW) widL = widX - spacingW;
    if(obj && obj !== null){
        obj.style.top = heiT + "px";
        obj.style.left = widL + "px";
    }
}

